import api from "./Repository";

const END_POINT = "/recordings";

export default {
    // GET
    getRooms() {
        return api.get(`${END_POINT}`);
    },
    getSessions(roomId) {
        return api.get(`${END_POINT}/${roomId}`);
    },
    getRecordings(roomId, sessionTime) {
        return api.get(`${END_POINT}/${roomId}/${sessionTime}`);
    },
    getPresignedURL(videoFileAddr) {
        return api.get(`${END_POINT}/${videoFileAddr}`);
    },

    // POST
    startRecord(payload) {
        return api.post(`${END_POINT}/start`, payload);
    },
    stopRecord(payload) {
        return api.post(`${END_POINT}/stop`, payload);
    }
};
