<template>
    <!-- eslint-disable vue/no-v-model-argument -->
    <main class="grid" :aria-label="$t('accesibility.media.recordings')">
        <!-- LOADER -->
        <loading v-model:active="loader.isLoading" :can-cancel="false" :is-full-page="true" color="#007680" :width="loader.loaderSize" :height="loader.loaderSize" :opacity="loader.opacity" />
        <!-- DATATABLE -->
        <div class="col-12 md:col-10 lg:col-10 xl:col-10 m-auto">
            <div class="card">
                <!-- SUBJECTS, ROOMS AND DIRECTORIES -->
                <DataTable v-if="roomsTable == true" selection-mode="single" :value="tableValues" responsive-layout="scroll" :rows="9" @rowSelect="onRoomSelect">
                    <template #header>
                        <div class="flex flex-wrap justify-content-start">
                            <div class="flex justify-content-center align-items-center">
                                <Button v-if="tableHeader != $t('app.menu.rooms')" icon="pi pi-arrow-left" class="p-button-rounded p-button-text p-button-plain" @click="navigateBack()"></Button>
                            </div>
                            <div class="flex justify-content-center align-items-center">
                                <h3 class="mr-auto mt-3 lg:m-3 xs:mb-2">{{ tableHeader }}</h3>
                            </div>
                        </div>
                    </template>
                    <Column field="name" :header="$t('endpoints.rooms.table.columns.name')" :sortable="true"></Column>
                </DataTable>

                <!-- RECORDINGS TEACHER -->
                <DataTable v-if="sessionsTable == true && userMode == 'teacher'" selection-mode="single" :value="tableValues" responsive-layout="scroll" :rows="9" @rowSelect="onSessionSelect">
                    <template #header>
                        <div class="flex flex-wrap justify-content-start">
                            <div class="flex justify-content-center align-items-center">
                                <Button v-if="tableHeader != $t('app.menu.rooms')" icon="pi pi-arrow-left" class="p-button-rounded p-button-text p-button-plain" @click="navigateBack(tableHeader)"></Button>
                            </div>
                            <div class="flex justify-content-center align-items-center">
                                <h3 class="mr-auto mt-3 lg:m-3 xs:mb-2">{{ tableHeader }}</h3>
                            </div>
                        </div>
                    </template>
                    <Column field="name" :header="$t('endpoints.rooms.table.columns.name')" :sortable="true"> </Column>
                </DataTable>
                <DataTable v-if="recordingsTable == true" :value="tableValues" responsive-layout="scroll" :rows="9">
                    <template #header>
                        <div class="flex flex-wrap justify-content-start">
                            <div class="flex justify-content-center align-items-center">
                                <Button v-if="tableHeader != $t('app.menu.rooms')" icon="pi pi-arrow-left" class="p-button-rounded p-button-text p-button-plain" @click="navigateBack(tableHeader)"></Button>
                            </div>
                            <div class="flex justify-content-center align-items-center">
                                <h3 class="mr-auto mt-3 lg:m-3 xs:mb-2">{{ tableHeader }}</h3>
                            </div>
                        </div>
                    </template>
                    <Column field="name" :header="$t('endpoints.rooms.table.columns.name')" :sortable="true"></Column>
                    <Column>
                        <template #body="tableValues">
                            <Button icon="pi pi-download" class="p-button-rounded" @click="downloadVideoFile(tableValues.data.addr)"></Button>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </main>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { getUserInfo } from "../utils/auth.js";
import { downloadVideo } from "../utils/utils.js";
import { RepositoryFactory } from "../meet-repositories/RepositoryFactory";
import { mapState } from "vuex";

const RecordingsRepository = RepositoryFactory.get("recordings");

export default {
    components: {
        Loading
    },
    data() {
        let { logo } = getUserInfo();
        return {
            roomsTable: true,
            sessionsTable: false,
            recordingsTable: false,
            tableHeader: "",
            tableValues: [],
            userMode: null,
            userId: null,
            roomId: "",
            sessionId: "",
            videoFileAddress: "",
            update: "",
            directoryName: "",
            directory: "",
            categoryId: "",
            roomDialog: false,
            displayConfirmation: false,
            displayUpdate: false,
            logo: logo,
            selectedRoom: null,
            selectedSession: null,
            button_recording: true,
            not_recording: false,
            recordings: [],
            filters: {
                is_deleted: {
                    value: false,
                    matchMode: "equals"
                }
            }
        };
    },
    computed: {
        ...mapState(["loader"])
    },
    created() {
        document.title = this.$t("accesibility.title.recordings");
        let { user_mode, user_id } = getUserInfo();
        this.userMode = user_mode;
        this.userId = user_id;
        if (this.userMode == "teacher") {
            this.getRooms();
        } else {
            this.$router.push("/access");
        }
    },
    methods: {
        async getRooms() {
            this.tableHeader = this.$t("app.menu.rooms");
            let response = await RecordingsRepository.getRooms();
            this.tableValues = response.data;
        },
        async getRoomSessions(roomId) {
            this.tableHeader = "Sesiones";
            this.roomId = roomId;
            this.tableValues = null;
            try {
                let response = await RecordingsRepository.getSessions(this.roomId);
                this.tableValues = response.data;
            } catch (error) {
                console.error(error);
            }
        },
        async getSessionRecordings(roomId, sessionId) {
            this.tableHeader = this.$t("app.menu.recordings");
            this.roomId = roomId;
            this.sessionId = sessionId;
            this.tableValues = null;
            try {
                let response = await RecordingsRepository.getRecordings(this.roomId, this.sessionId);
                this.tableValues = response.data;
            } catch (error) {
                console.error(error);
            }
        },
        async downloadVideoFile(videoFileAddress) {
            this.videoFileAddress = videoFileAddress;
            try {
                let response = await RecordingsRepository.getPresignedURL(this.videoFileAddress);
                const videoURL = response.data.url;

                downloadVideo(videoURL);
            } catch (error) {
                console.error(error);
            }
        },
        onRoomSelect(room) {
            this.roomsTable = false;
            this.sessionsTable = true;
            this.recordingsTable = false;
            this.tableHeader = this.$t("app.menu.recordings");
            this.selectedRoom = room.data.name;
            this.getRoomSessions(this.selectedRoom);
        },
        onSessionSelect(session) {
            this.roomsTable = false;
            this.sessionsTable = false;
            this.recordingsTable = true;
            this.tableHeader = this.$t("app.menu.recordings");
            this.selectedSession = session.data.name;
            this.getSessionRecordings(this.selectedRoom, this.selectedSession);
        },
        navigateBack() {
            if (this.sessionsTable) {
                this.getRooms();
                this.sessionsTable = false;
                this.roomsTable = true;
            } else if (this.recordingsTable) {
                this.getRoomSessions(this.selectedSession);
                this.recordingsTable = false;
                this.sessionsTable = true;
            }
        }
    }
};
</script>
