import axios from "axios";
import router from "../router";
import store from "@/store";
// import { env_config } from "../../config";

// const baseDomain = process.env.VUE_APP_API_BASE_DOMAIN
// const baseDomain = env_config.API_BASE_DOMAIN;
const baseURL = "https://4q0847t4m8.execute-api.eu-west-1.amazonaws.com/prod";

const api = axios.create({
    baseURL
});

api.interceptors.request.use(
    (config) => {
        store.commit("loading", true);
        return config;
    },
    (error) => {
        store.commit("loading", false);
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    (res) => {
        store.commit("loading", false);
        return res;
    },
    (error) => {
        store.commit("loading", false);
        if (error.response.status == 401) {
            router.push("/access");
        }
        return Promise.reject(error);
    }
);

export default api;
